import { RaceNode } from "../pages/historie";
import React from "react";
import { ResponsiveYoutube } from "./responsive-youtube";

export const RaceDetail = ({ race, className, extraLinks }: Props) => <div className={className}>
    <h3>{race.node.frontmatter.title}</h3>
    <div>{race.node.frontmatter.formattedDate}</div>
    <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 pr-3">
            <h4>Popis</h4>
            <div className="markdown" dangerouslySetInnerHTML={{ __html: race.node.html }}></div>
        </div>
        <div className="w-full md:w-1/2 md:pl-3">
            <h4>Kam dál?</h4>
            {race.node.frontmatter.resultLink && <div><a href={race.node.frontmatter.resultLink} rel="noopener noreferrer" target="_blank">Výsledky</a></div>}
            {race.node.frontmatter.galleryLink && <div><a href={race.node.frontmatter.galleryLink} rel="noopener noreferrer" target="_blank">Fotky</a></div>}
            {extraLinks}
            {race.node.frontmatter.youtubeId && <ResponsiveYoutube className="my-3" youtubeId={race.node.frontmatter.youtubeId} />}
        </div>
    </div>
</div>

interface Props {
    race: RaceNode
    className?: string
    extraLinks?: React.ReactNode
}