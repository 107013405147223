import React from "react";

import Layout, { LayoutData } from "../components/layout";
import SEO from "../components/seo";
import { graphql, Link } from "gatsby";
import { RaceNode } from "./historie";
import { CustomLink } from "../components/custom-link";
import { PageSection } from "../components/page-section";
import { RaceDetail } from "../components/race-detail";
import Img from "gatsby-image";
import { FluidImage } from "../utils/sharpImages";
import BackgroundImage from "gatsby-background-image"
import { MapWithMarker } from "../components/mapWithMarker";

const HomePage = (props: Props) => {

  const { frontmatter: { title, subtitle, shortDescription, logo, heroPhoto, ctaButton, location, schedules, gallery }, html, fields: { summaries } } = props.data.homePageData
  const race = props.data.raceData.edges[0]
  return (
    <Layout {...props}>
      <SEO
        title="Závod"
      />

      <section className="text-center">
        <BackgroundImage fluid={heroPhoto.childImageSharp.fluid}>
          <div className="py-3"></div>
          <Img
            alt={title}
            className="block mx-auto w-1/2 my-3"
            fluid={logo.childImageSharp.fluid}
          />
          <h2 className="text-white text-3xl font-bold p-3">{title}</h2>
          <div className="text-white">{subtitle}</div>
          <div className="text-white text-4xl font-bold">{race.node.frontmatter.formattedDate}</div>
          {ctaButton && <CustomLink className="bg-highlight text-white hover:text-white text-2xl font-bold inline-block my-8 py-3 px-5 shadow-lg hover:shadow-none" linkType={ctaButton.linkType} linkURL={ctaButton.linkURL}>
            {ctaButton.title}
          </CustomLink>}
        </BackgroundImage>
      </section>
      <PageSection>
        <div className="text-center">{shortDescription}</div>
      </PageSection>
      {summaries.length > 0 && <PageSection>
        <div className="flex">
          {summaries.map((summary, i) => <div className="flex-1 text-center p-2" dangerouslySetInnerHTML={{ __html: summary.summary }} key={i}></div>)}
        </div>
      </PageSection>}
      <PageSection className="bg-light shadow-2xl">
        <RaceDetail extraLinks={<>
          <div><Link to="/trasa">Trasa</Link></div>
          <div><Link to="/pravidla">Pravidla</Link></div>
        </>} race={race} />
      </PageSection>
      <PageSection>
        <div>
          <h3>Harmonogram</h3>
          <div>Datum: {race.node.frontmatter.formattedDate}</div>
          <div className="flex justify-between -ml-3">
            {schedules.map((schedule, i) =>
              <div className="flex-auto px-3" key={i}>
                <h4>{schedule.title}</h4>
                <ul>
                  {schedule.scheduleItems.map(item => <li key={item.time}>{item.time}: {item.text}</li>)}
                </ul>
              </div>)}
          </div>
        </div>
      </PageSection>
      {location && <section>
        <div className="max-w-4xl mx-auto px-4 py-8 md:p-8">
          <h3>Místo</h3>
          <div>{location.name}</div>
          <div>{location.description} (GPS: {location.mapsLatitude}, {location.mapsLongitude}), <a href={location.mapsLink} rel="noopener noreferrer" target="_blank">Odkaz na mapu</a></div>
        </div>
        {process.env.GATSBY_GOOGLE_MAP_KEY &&
          <div className="w-full h-full" style={{ height: `400px` }}>
            <MapWithMarker apiKey={process.env.GATSBY_GOOGLE_MAP_KEY} lat={location.mapsLatitude} lng={location.mapsLongitude} title="Tour de Ralsko" />
          </div>}

      </section>}
      <PageSection>
        <div className="markdown" dangerouslySetInnerHTML={{ __html: html }}></div>
      </PageSection>
      {ctaButton && <PageSection>
        <div className="text-center">
          <CustomLink className="bg-highlight text-white hover:text-white text-3xl font-bold inline-block my-8 py-4 px-6 shadow-lg hover:shadow-none" linkType={ctaButton.linkType} linkURL={ctaButton.linkURL}>
            {ctaButton.title}
          </CustomLink>
        </div>
      </PageSection>}
      {race.node.frontmatter.sponsorsImage && <PageSection>
        <h3>Sponzoři</h3>
        <Img alt="Sponsors" fluid={race.node.frontmatter.sponsorsImage.childImageSharp.fluid} />
      </PageSection>}
      {gallery && <PageSection>
        <h3>Galerie</h3>
        <div className="clearfix">
          {gallery.map((image, i) => <div className="w-1/2 md:w-1/3 p-3 float-left" key={i}><Img alt={image.imageAlt} fluid={image.image.childImageSharp.fluid} title={image.imageAlt} /></div>)}
        </div>
      </PageSection>}
    </Layout >
  );
}

interface Schedule {
  title: string
  scheduleItems: {
    time: string
    text: string
  }[]
}

interface Props {
  data: {
    homePageData: {
      frontmatter: {
        title: string,
        subtitle: string,
        shortDescription: string,
        logo: FluidImage
        heroPhoto: FluidImage
        ctaButton?: {
          title: string
          linkType: `internal` | `external`
          linkURL: string
        },
        schedules: Schedule[]
        location?: {
          description: string
          mapsLatitude: number
          mapsLink: string
          mapsLongitude: number
          name: string
        },
        gallery?: [{
          image: FluidImage
          imageAlt: string
        }]
      }
      html: string
      fields: {
        summaries: { summary: string }[]
      }
    },
    raceData: {
      edges: RaceNode[]
    }
  } & LayoutData
}

export const pageQuery = graphql`
  query HomePageQuery {
          homePageData: markdownRemark(frontmatter: {contentId: {eq: "homepage"}}) {
            id
            frontmatter {
              contentId
              title
              subtitle
              shortDescription
              logo {
                childImageSharp {
                  fluid(maxWidth: 896) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              heroPhoto {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              ctaButton {
                linkType
                linkURL
                title
              }
              schedules {
                title
                scheduleItems {
                  text
                  time
                }
              }
              location {
                name
                description
                mapsLatitude
                mapsLink
                mapsLongitude                
              }
              gallery {
                image {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                imageAlt
              }
            }
          html
          fields {
            summaries {
              summary
            }
          }
}
raceData:  allMarkdownRemark(
      filter:{frontmatter:{contentId: {eq: "race"}}},
      sort:{fields: [frontmatter___date], order: DESC},
      limit: 1) {
          edges {
          node {
          id
          frontmatter {
            contentId
            title
            formattedDate: date(formatString: "l", locale: "cs")
            youtubeId
            resultLink
            galleryLink
            sponsorsImage {
              childImageSharp {
                fluid(maxWidth: 896) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        html
  }
}
}
...LayoutFragment
}`

export default HomePage;
