import React from "react";

export const ResponsiveYoutube = ({ youtubeId, className }: { youtubeId: string, className?: string }) => <div className={`relative ${className || ``}`} style={{ paddingBottom: `56.25%` }}>
    <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        className="absolute pin w-full h-full"
        frameBorder="0"
        src={`https://www.youtube.com/embed/${youtubeId}`}
        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${youtubeId}?autoplay=1><img src=https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg alt='Video'><span>▶</span></a>`}
        title="Video"
    ></iframe>
</div>