import GoogleMapReact from "google-map-react"
import React from "react"


const Marker = ({ title }: MarkerProps) => <div className="p-3 h-18 w-18 bg-highlight text-white text-sm border-4 border-white text-center align-middle" style={{
    position: `absolute`,
    transform: `translate(-50%, -50%)`
}}>{title}</div>

interface MarkerProps {
    lat: number
    lng: number
    title: string
}

export const MapWithMarker = (props: Props) => {

    return <GoogleMapReact
        bootstrapURLKeys={{ key: props.apiKey, language: `cs` }}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        defaultZoom={8}
    >
        <Marker lat={props.lat} lng={props.lng} title={props.title} />
    </GoogleMapReact>
}


interface Props {
    lat: number
    lng: number
    title: string
    apiKey: string
}